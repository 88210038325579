.subscription-page {
    padding: 40px 20px;
    text-align: center;
    background-color: #1e1e1e;
    color: #e0e0e0;
}

h1 {
    text-align: center;
    color: #60a5fa;
    margin-bottom: 2rem;
}

.plan-options {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.plan {
    background-color: #2a2a2a;
    padding: 20px;
    border-radius: 16px;
    width: 300px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    position: relative;
}

.plan:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(187, 134, 252, 0.1);
}

.plan.selected {
    border: 2px solid #bb86fc;
    box-shadow: 0 0 15px rgba(187, 134, 252, 0.5);
}

.plan h2 {
    color: #bb86fc;
    margin-bottom: 1rem;
}

.plan .price {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.plan .original-price {
    text-decoration: line-through;
    color: #94a3b8;
    font-size: 0.9em;
}

.plan .discounted-price {
    color: #60a5fa;
    font-size: 1.2em;
}

.plan .offer-ends {
    font-size: 0.9em;
    color: #ef4444;
    margin-top: 5px;
    font-weight: bold;
}

.plan ul {
    list-style-type: none;
    padding: 0;
    color: #b0bec5;
    margin-bottom: 1rem;
}

.plan li {
    color: #94a3b8;
    margin-bottom: 10px;
}

.plan li::before {
    content: "✓";
    color: #60a5fa;
    margin-right: 0.5rem;
}

.get-started-button {
    background-color: #4f46e5;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.get-started-button:hover {
    background-color: #4338ca;
}

.popular {
    border: 2px solid #60a5fa;
    position: relative;
}

.popular-badge {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #60a5fa;
    color: white;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: bold;
}

@media (max-width: 768px) {
    .subscription-page {
        padding: 20px 10px;
    }

    .plan {
        width: 100%;
        margin-bottom: 20px;
    }
}