@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0f172a;
  color: #e2e8f0;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #1e293b;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.chat-header {
  background-color: #0f172a;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #334155;
}

.chat-header-left {
  display: flex;
  align-items: center;
}

.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 1rem;
  border: 2px solid #60a5fa;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-text h1 {
  margin: 0;
  color: #60a5fa;
  font-size: 1.5rem;
  font-weight: 600;
}

.header-text p {
  margin: 0;
  color: #94a3b8;
  font-size: 0.9rem;
}

.premium-button {
  background-color: #4f46e5;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.premium-button:hover {
  background-color: #4338ca;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.message {
  display: flex;
  flex-direction: column;
  max-width: 70%;
  animation: fadeIn 0.3s ease-out;
}

.message.user {
  align-self: flex-end;
}

.message.bot {
  align-self: flex-start;
}

.message-content {
  display: flex;
  align-items: flex-start;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #334155;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.message.bot .avatar {
  background-color: #60a5fa;
  order: -1;
  margin-right: 1rem;
  margin-left: 0;
}

.text {
  background-color: #334155;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.message.user .text {
  background-color: #3b82f6;
  color: white;
}

.message.bot .text {
  background-color: #1e293b;
  color: #e2e8f0;
}

.message-time {
  font-size: 0.8rem;
  color: #94a3b8;
  margin-top: 0.5rem;
  align-self: flex-end;
}

.chat-input {
  display: flex;
  padding: 1.5rem 2rem;
  background-color: #0f172a;
  border-top: 1px solid #334155;
}

.chat-input input {
  flex-grow: 1;
  padding: 1rem;
  border: none;
  border-radius: 30px;
  background-color: #1e293b;
  color: #e2e8f0;
  font-size: 1rem;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.chat-input input:focus {
  outline: none;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(99, 102, 241, 0.5);
}

.chat-input button {
  padding: 1rem;
  border: none;
  background-color: #4f46e5;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 1rem;
  transition: all 0.3s ease;
}

.chat-input button:hover {
  background-color: #4338ca;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.typing-indicator {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.typing-indicator span {
  height: 8px;
  width: 8px;
  background-color: #94a3b8;
  border-radius: 50%;
  display: inline-block;
  animation: pulse 1s infinite ease-in-out;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes pulse {
  0%, 100% { transform: scale(1); opacity: 0.4; }
  50% { transform: scale(1.1); opacity: 1; }
}

/* Custom scrollbar styles */
.chat-messages::-webkit-scrollbar {
  width: 8px;
}

.chat-messages::-webkit-scrollbar-track {
  background: #1e293b;
}

.chat-messages::-webkit-scrollbar-thumb {
  background: #4b5563;
  border-radius: 4px;
}

.chat-messages::-webkit-scrollbar-thumb:hover {
  background: #60a5fa;
}

@media (max-width: 768px) {
  .chat-container {
    height: 100vh;
    width: 100vw;
  }

  .chat-header {
    padding: 1rem;
  }

  .chat-messages {
    padding: 1rem;
  }

  .message {
    max-width: 85%;
  }

  .chat-input {
    padding: 1rem;
  }
}

.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #4caf50; /* Green background */
  color: white; /* White text */
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Ensure it appears above other elements */
  animation: fadeIn 0.5s, fadeOut 0.5s 4.5s; /* Fade in and out */
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}
.original-price {
  text-decoration: line-through;
  color: #94a3b8;
  font-size: 0.9em;
  margin-right: 10px;
}

.discounted-price {
  font-weight: bold;
  color: #60a5fa;
  font-size: 1.2em;
}

.offer-ends {
  font-size: 0.9em;
  color: #ef4444;
  margin-top: 5px;
  font-weight: bold;
}

.pricing-tier .price {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}